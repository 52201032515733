@import "../../app/colors.less";

@bottom-tab-bar-height: 60px;

.bottom-tabbar-container {
  width: 100%;
  z-index: 510;
  background-color: @background;
  height: @bottom-tab-bar-height;
}

.bottom-tabbar-background {
  background-color: @background;
  height: env(safe-area-inset-bottom);
  width: 100%;
}

.adm-tab-bar-item-icon {
  font-size: 25px;
  margin-bottom: 5px;
}

.adm-tab-bar-item {
  color: white;
  background-color: @background;
}

.adm-tab-bar-item-active {
  color: @background;
  background-color: white;
  border-top: 1px solid #dddd;
  box-shadow: inset 0 10px 10px -10px rgba(0, 0, 0, 0.2);
}

.adm-tab-bar,
.adm-tab-bar-wrap {
  height: @bottom-tab-bar-height;
}

.adm-tab-bar .adm-tab-bar-item-title {
  font-size: 12px;
}

@primary-color: #3c61b4;