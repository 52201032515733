.settings-page {
  display: flex;
  flex: 1 1;
  padding-bottom: 10px;
  min-height: 50vh;
}

.settings-menu {
  width: 250px;
  border-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
}

.settings-body {
  flex: 1 1;
  background-color: white;
  padding: 20px;
  margin-left: 10px;
  border-radius: 5px;
  overflow: auto;
}

.settings-form {
  max-width: 400px;
}

.settings-icon {
  padding-top: 3px;
}

@media only screen and (max-width: 800px) {
  .settings-menu {
      width: 50px;
  }

  .settings-body {
      margin-left: 5px;
  }
}

@media only screen and (min-width: 1200px) {
  .ant-radio-group {
      float: left !important;
  }
}
