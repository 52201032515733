@import '../../app/colors.less';

@date-picker-button-size: 40px;

.farmhand-content {
  .card-layout-style {
    margin-top: 15px;
  }

  .ant-typography {
    margin: 0;
    cursor: var(--default-cursor);
  }

  .page-header {
    margin-top: 10px
  }
}

.farmhand-ranch-block-list {
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 5px;
}

.farmhand-ranch-block-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: orange;
  padding: 20px;
  border-radius: 10px;
  text-align: center;

  .ant-typography {
    color: white;
    text-transform: capitalize;
  }
}

.farmhand-date-time-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.farmhand-date-time-selection {
  column-gap: 10px;
  justify-content: space-between;

  .item {
    flex-grow: 1;
  }

  .farmhand-date-selection {
    width: 100%;
  }

  div.ant-picker {
    background-color: white;
    border: none;
    border-radius: 5px;
    flex-grow: 1;
  }

  div.ant-picker {
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.3);
    border: 0.5px solid rgba(0, 0, 0, 0.2);
  }

  .time-icon {
    margin-right: 10px;
  }

  .loading-schedule {
    font-size: 24px;
    padding: 5px;
    width: @date-picker-button-size;
    height: @date-picker-button-size;
  }

  .delete-schedule {
    border: none;
    background: none;
    font-size: 24px;
    color: @color-state-critical;
    display: flex;
    align-items: center;
    height: @date-picker-button-size;
    width: @date-picker-button-size;
  }
}

.farmhand-valve-list {
  margin-top: 10px;
}

.farmhand-valve-item {
  display: flex;
  align-items: center;
  padding: 10px;

  &:nth-child(odd) {
    background: #F2F2F2;
  }

  .flex-item {
    flex: 1;
  }

  .pressure-gauge-container {
    display: flex;
    justify-content: center;
  }

  .valve-status-container {
    display: inline-block;
    background: black;
    padding: 10px;
    border-radius: 10px;
    position: relative;

    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 25px;
    }
  }

  .valve-status-name {
    .ant-typography {
      color: white;
      text-align: center;
      margin-bottom: 5px;
    }
  }

  .valve-status {
    display: flex;
    background: white;
    border-radius: 5px;

    .valve-status-item {
      padding: 5px;
      cursor: pointer;
      text-align: center;
      flex: 1;
      width: 45px;
    }

    .selected {
      border-radius: 5px;
    }

    .valve-status-on.selected {
      background: #50AE2B;
    }

    .valve-status-off.selected {
      background: #CF302B;
    }
  }

  .pressure-canvas-container {
    height: 80px;

    canvas {
      left: -11px;
      top: -40px;
    }

    &::after {
      top: 35px;
      left: 44px;
    }
  }

  .pressure-value {
    margin: 0;
    margin-top: 8px;
    text-align: center;
  }

  @media screen and (max-width: 390px) {
    .pressure-value {
      font-size: 28px;
      flex: 2;
    }
  }

  @media screen and (max-width: 375px) {
    .pressure-value {
      font-size: 24px;
    }
  }
}

@primary-color: #3c61b4;