.graph-page {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

.chart-container-div-style {
    height: calc(100% - 8px);
}

.multi-graph-page {
    overflow: auto;
}

.header-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.preset-container{
    display: flex;
    justify-content: flex-start;
    width: 30%;
    min-width: 260px;
    gap: 0.5rem;
    align-items: center;
}

#presetSelect{
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    width: 50%;
    align-items: center;    
}

.preset-select-label{
    width: 30%;
 }

#presetSelect > .ant-select{
    width: 70%;
}

.uniform-y-axis-checkbox {
    width: 50%;
}

.charts-dropdown {
    width: 45%;
    padding: 0px 5px;
    align-self: center;
}

.soil-preset-chart-select {
    width: 100%;
    position: relative;
}

.graph-date-range-picker .ant-picker.ant-picker-range {
    width: 100%;
    left: 0;
}

.date-range-shortcuts {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    flex-wrap: wrap;
}

.soil-moisture-individual-depth {
    margin-bottom: 5px;
}

.edit-cutoff-points-modal .ant-modal-header, .edit-cutoff-points-modal .ant-modal-footer {
    background: #eee;
}

.graph-page .ant-page-header-heading {
    padding: 0px 20px;
    margin-bottom: -10px;
}

@media screen and (min-width: 960px){


    .graph-date-range-picker{
        width: 30%;
        min-width: 480px;
        flex-wrap: wrap;
    }

    .today-button, .week-button, .month-button {
        width: 14%;
    }

    .previous-button, .next-button {
        width: 24%;
    }

    .date-range-shortcut-button {
        text-align: center;
    }
}

@media screen and (max-width: 960px) {
    .graph-page .ant-page-header-heading {
        margin-top: 8px;
        padding: 0px 10px;
    }

    .header-container {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }
    .preset-container{
        order: 1;
        display: flex;
        width: 100%;
        align-items: center;
        max-width: inherit;
    }

    .preset-select-label{
       display:none;
    }

    .preset-select > .ant-select{
        width: 30vw;
    }

    .uniform-y-axis-checkbox {
        text-align: end;
        .ant-checkbox {
            align-self: auto;
        }
    }

    .charts-dropdown {
        order: 2;
        width: 100%;
        padding: 0px;
    }

    .charts-dropdown .ant-select-dropdown {
        position: fixed;
        height: 100%;
    }

    .ant-select-tree-list-holder:after{
        content: 'Select Chart and Click Anywhere Below To Close';
        font-weight: bold;
        color: lightgray
    }


    .soil-preset-chart-select {
        width: 100%;
        position: relative;
    }

    .graph-date-range-picker {
        width: 100%;
    }

    .date-range-shortcuts {
        justify-content: space-between;
        margin-top: 0.3rem;
    }

    .today-button, .week-button, .month-button {
        width: 18%;
    }

}

.graph-page .page-body {
    display: flex;
    flex-direction: column;
}

.add-graph {
    border-color: blue;
    border-radius: 2px;
    background-color: #cccccc;
    padding: 5px;
}

.add-graph .ant-form-inline .ant-form-item-with-help {
    margin-bottom: 0px;
}

.graph-list>* {
    margin: 10px 0px;
}

.graph-list :first-child.chart-container {
    margin: 0px 0px;
}

.graph-list {
    padding-top: 1px;

    .legend-pill {
        display: inline-block;
        padding: 0.1em 0.1em;
        color: white;
        font-weight: bold;
        font-size: 0.9em;
        width: 4em;
        border-radius: 1rem;

        &:hover,
        &:focus {
            color: white;
        }
    }

    .legend-pill-root-zone {
        width: 5em;
        padding-left: 0.3em;
    }

    .legend-pill-not-root-zone {
        width: 7em;
        padding-left: 0.5em;
    }
}

.chart-container {
    flex: 1;
}

.load-more-button {
    text-align: center;
}

.flex-input-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.flex-input-group>*:not(:first-child) {
    margin-left: 10px;
}

/* SensorDataChart styles */
.chart-container {
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 2px;
}

.chart-header-container {
    background-color: #eee;
}

.chart-header-form {
    display: flex;
    padding: 5px 5px;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: space-between;
}

.chart-header-form>* {
    margin: 0px 5px;
}

.chart-header-form>.chart-variables {
    flex: 1;
    display: flex;
}

.chart-header-left {
    display: flex;
    justify-content: space-between;
}

.chart-header-legend {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-start;
}


.chart-header-right {
    display: flex;
    width: 20%;
    justify-content: flex-end;
    column-gap: 10%;
    height: 3vh;
}

.chart-header-edit-link {
    cursor: pointer;
    width: 90%;
}

.chart-header-switch-mode-icons {
    width: 90%;
    color: #1890ff;
    cursor: pointer;
    margin-left: 20px;
    width: 24px;
    height: 24px;
}

.chart-png {
    background-image: url('../../assets/images/chart.png');
}

.multi-line-png {
    background-image: url('../../assets/images/multi-line.png');
}

.stacked-line-png {
    background-image: url('../../assets/images/stacked-line.png');
}

.icon-border {
    border: solid 3px;
    border-radius: 20%;
    height: 30px;
    width: 30px;
}

.chart-sensor {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    font-weight: bold;
}

.chart-sensor>.chart-title {
    margin-left: 5px;
    margin-right: 5px;
    font-weight: normal;
}

@media screen and (max-width: 960px) {
    .chart-header-legend {
        flex-wrap: wrap;
    }

    .chart-header-left {
        flex-wrap: wrap;
    }

    .chart-sensor {
        flex-wrap: wrap;
    }

    .chart-sensor>.chart-title {
        margin-left: 0px;
    }

    .chart-header-edit-link {
        margin-left: 10px;
    }
}

.chart-loading {
    color: #a2a2a2;
    display: flex;
    height: 100%;
}

.chart-loading-inner{
    font-weight: bold;
    margin: auto;
}

.highcharts-no-data {
    color: #a2a2a2;
}

.chart-component-main {
    background-color: #fff;
    height: 200px;
}

.chart-sm .chart-component-main {
    height: 100px;
}

.chart-md .chart-component-main {
    height: 200px;
}

.chart-lg .chart-component-main {
    height: 400px;
}

.graph-row-gap {
    row-gap: 10px;
    position: relative;
}

.lower-band-line,
.upper-band-line {
    opacity: 1 !important;
}

.highcharts-plot-band {
    opacity: 0.1;
}



.chart-sm .nested-individual-chart,
.chart-md .nested-individual-chart,
.chart-lg .nested-individual-chart,
.nested-individual-chart {
    height: 150px;
}

.nested-individual-chart-main {
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
}

.nested-individual-chart-header {
    background: #eee;
    padding: 6px;
    display: flex;
    justify-content: space-between;
}

.individual-chart-header {
    display: flex;
    align-items: center;
    gap: 10px;

    .sensor-name {
        font-weight: bold;
    }
}

.chart-component-wrapper {
    position: relative;
}

.chart-component-main-edit-buttons {
    position: absolute;
    display: flex;
    top: -35px;
    right: 5px;
    gap: 5px;
}

.edit-sensor-settings {
    display: flex;
    flex-direction: column;
    background: white;

    .edit-sensor-settings-header {
        display: flex;
        justify-content: space-between;
        padding: 5px;
    }

    .edit-sensor-settings-rootzone-info,
    .edit-sensor-save-cancel {
        display: flex;
        gap: 5px;
        align-items: center;
    }

    .cutoff-input-container {
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 500px;
    }
}

.edit-soil-moisture-cutoff-points {
    display: flex;
    flex-direction: column;
    background: white;
    width: 100%;

    .edit-soil-moisture-cutoff-points-header {
        display: flex;
        justify-content: space-between;
        padding: 5px;
    }

    // .cutoff-points-radio .ant-radio-button-wrapper-checked {
    //     color: white;
    //     background: @color-state-over;
    //     border-color: @color-state-over;
    // }


    .cutoff-input-container {
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 500px;
    }

    .edit-soil-moisture-cutoff-points-rootzone-info {
        padding-top: 5px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

@media screen and (max-width: 960px) {

    .edit-soil-moisture-cutoff-points-header {
        flex-direction: column-reverse;
    }

    .edit-soil-moisture-cutoff-points {
        .edit-soil-moisture-cutoff-points-rootzone-info {
            padding-left: 0.1rem;
            flex-direction: row-reverse;
            max-width: 100%;
        }
    }

    .individual-chart-header {
        flex-wrap: wrap;
    }

    .chart-header-text {
        display: none;
    }

    .graph-list .legend-pill {
        font-size: .8em;
    }
}

.cutoff-points-inputs {
    display: flex;
    & .ant-input-number {
        width: 60px;
        margin: 0 2%;
      }
}

.field-capacity, .wilting-point {
    width: 25%;
    display: flex;
    justify-content: flex-start;
    height: 3vh;
}

.refill-percent {
    width: 50%;
    display: flex;
    justify-content:center;
    height: 3vh;
    align-items: center;
}

.refill-values  {
    display: flex;
    justify-content: flex-end;
    width: 50%;
}

// Simple Sensor Select Component Styles
.graph-page-sensor-select {
    display: flex;
    gap: 5px;
    width: 55%;

    .graph-page-sensor-select-label {
        align-content: center;
    }

    .select-component {
        width: 95%;
        height: 100%;

        .ant-select-selector {
            height: 100%;
        }
    }

    .graph-page-sensor-select-clear {
        align-content:end;   
        cursor: pointer;
        color: hsl(0, 0%, 74%);     
    }

    .graph-page-sensor-select-clear:hover {
       color: hwb(0 0% 100%);
    }
}

.sensor-compare {
    margin-left: 2%;
    display: flex;
    gap: 5px;
    width: 45%;
    justify-content: start;

    span {
        align-content: center;
    }

    .sensor-select-compare {
        width: 40%;
    }
}

@media screen and (max-width: 960px) {
    .cutoff-points-inputs {
        flex-direction: column;
        gap: 10px;
    }

    .field-capacity, .wilting-point, .refill-percent {
        width: 100%;
        align-items: inherit;
        justify-content: flex-start;
    }

    .refill-values  {
        display: flex;
        justify-content: flex-end;
        width: 62%;
    }

    .graph-page-sensor-select {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 50%;

        .graph-page-sensor-select-label {
            order: 1;
        }
    
        .select-component {
            width: 85%;
            order: 2;
            height: auto;

            .ant-select-selector {
                height: 100%;
            }
        }

        .graph-page-sensor-select-clear {
            width: 10%;
            order: 3;
            align-content: center;
        }
    }
 
    .sensor-compare {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 50%;

        .sensor-select-compare {
            width: 100%;
        }
    }

    .graph-page-ranch-block-selector {
        margin: 11px 10px -3px 11px;
    }
}

// Sensor selectors row
.sensor-selectors {
    display: flex;
    flex-direction: row;
    width: 100%;
}

@media screen and (min-width: 768px) and (max-width: 1550px) {
    .sensor-selectors {
        flex-wrap: wrap;
        gap: 15px;
    }

    .sensor-compare {
        margin-left: -3px;
    }
}
@primary-color: #3c61b4;