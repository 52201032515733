.water-drop-icon-container {
  display: block;
  min-width: 70px;
  margin-left: 50px;
  margin-top: 5px;
}

.irrigation-status-container {
  display: flex;
  padding: 10px;
}

.water-drop-icon-container svg {
  width: 80px;
  margin-left: -60px;
}

.irrigation-status-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.irrigation-status-calendar {
  background: white;
  font-size: 35px;
  padding: 10px 20px;
  border-radius: 10px;
  margin-right: 20px;
}

.irrigation-status-content .ant-typography,
.irrigation-status-content .ant-typography + .ant-typography {
  margin: 0;
}

.irrigation-status-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.irrigation-status-title {
  font-size: 18px;
}
