.recommendation-action-container {
  overflow-y: auto;
  margin-bottom: 50px;
}

.recommendation-action-container-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 4px;
}

.recommendation-action-card-date {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.recommendation-action-card-duration {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.recommendation-action-card-label {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center,
}

.recommendation-action-card-repeat {
  margin-top: 20px;
}

.recommendation-action-card-link-container {
  margin-top: 15px;
}

.recommendation-action-card-link {
  padding: 20px;
  font-weight: bold;
}

.recommendation-action-card-btn-container {
  width: 100%;
}

.recommendation-buttons.recommendation-action-card-btn-container {
  bottom: calc(60px + env(safe-area-inset-bottom));
}

.duration-slider-container {
  display: flex;
  min-height: 240px;
  padding: 20px;
}

.recommendation-container {
  position: relative;
  padding-bottom: 0px;
}

.map-left-arrow {
  float: left;
  z-index: 100;
}

.map-right-arrow {
  float: right;
  z-index: 100;
}

.anomaly-bottomsheet-block .recommendation-container {
  padding-bottom: 0px !important;
}

@media (max-width: 900px) {
  .recommendation-buttons {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: calc(60px + env(safe-area-inset-bottom));
    background-color: #dddddd;
    z-index: 3;
    padding-top: 12px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    max-width: 900px;
  }
}

@media (min-width: 900px) {
  .recommendation-container {
    height: auto;
    display: block;
  }
}

.drawer-desktop 
.ant-drawer-content-wrapper 
.ant-drawer-wrapper-body 
.ant-drawer-body 
.anomaly-details-container {
  overflow: auto;
  height: calc(100vh - 20vh);
}

.drawer-desktop .ant-drawer-content-wrapper .ant-drawer-wrapper-body .ant-drawer-body {
  overflow-x: hidden;
}

/* this is for thinner scroll-bar.
this can be removed if UI experience 
does not look good */
.anomaly-details-container::-webkit-scrollbar {
    width: 5px;
  }
  ​
  /* Track */
  .anomaly-details-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(80, 79, 79); 
    border-radius: 5px;
  }
  
  /* Handle */
  .anomaly-details-container::-webkit-scrollbar-thumb {
    background: var(--scrollbar-color); 
    border-radius: 5px;
  }

.anomaly-details-container .ant-card,
.anomaly-details-container .page-header{
  margin-bottom: 10px ;
}

.drawer-desktop 
.ant-drawer-content-wrapper 
.ant-drawer-wrapper-body 
.ant-drawer-body 
.anomaly-action-button {
  margin-left: 0px;
}

.drawer-desktop 
.ant-drawer-content-wrapper 
.ant-drawer-wrapper-body 
.ant-drawer-body 
.scheduling-investigation-button{
  bottom: 24px;
}

.anomaly-bottomsheet-block .recommendation-anomaly-action-buttons {
  background-color: var(--active-tab-text-color);
}

.drawer-desktop 
.ant-drawer-content-wrapper 
.ant-drawer-wrapper-body 
.ant-drawer-body 
.scheduling-investigation-button {
  position: relative;
  background-color: var(--active-tab-text-color);
}

.recommendation-anomaly-action-buttons, 
.recommendation-investigate-button  {
  position: fixed;
  width: 100%;
  bottom: 60px;
  background-color: var(--desktop-recommendation-bg-color);
  z-index: 400;
  padding: 10px 5px !important;
  max-width: 880px;
  margin-left: -5px;
}

.recommendation-investigate-button {
  bottom:  0px;
  padding: 5px !important;
  padding-bottom: 15px !important;
}

.desktop-imagery-anomaly .recommendation-investigate-button {
  position: relative;
  margin-top: -30px !important;
  margin-left: 0px;
}

#ground-mission-select-drone-imagery .recommendation-investigate-button {
  padding-bottom: 0px !important;
}

@media only screen and (min-device-width: 320px) 
and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) 
and (orientation: portrait) {

  .anomaly-action-button {
    margin-left: 0px;
    z-index: 9999;
  }

  .anomaly-details-mobile-container .ant-card,
  .anomaly-details-mobile-container .page-header {
    margin-bottom: 10px !important;
  }
  
  .recommendation-anomaly-action-buttons {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: calc(env(safe-area-inset-bottom) + 60px);
    background-color: var( --background-color);
    z-index: 400;
    padding: 12px 10px 10px 10px !important;
    max-width: 900px;
  }

  [data-rsbs-footer] .recommendation-anomaly-action-buttons {
    background-color: var(--active-tab-text-color);
  }

  [data-rsbs-overlay] .anomaly-details-margin {
    margin-bottom: -15px !important;
  }

  .anomaly-bottomsheet-block 
  .recommendation-detail-card2-top.map-anomaly-graph-buttons {
    display: none;
  }

  .recommendation-container {
    padding-bottom: 55px !important;
  }

  .map-left-arrow {
    margin-left: 7px;
  }
  
  .map-right-arrow {
    position: absolute;
    left: calc(100vw - 113px);
    top: 3px;
  }
}