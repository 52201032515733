.vwc-chart-container {
    background-color: #aaa;
    height: 200px;
    margin-top: 10px;
}

.graphDiv {
    height: 200px;
    margin-top: -10px;
    margin-left: -10px;
}

.summaryDiv {
    margin-left: -10px;
    height: 200px;
}
@primary-color: #3c61b4;