.layout-mobile .content-container {
  padding: 0;
  display: block !important;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.uppercase {
  text-transform: uppercase;
}

.uppercase-bold {
  text-transform: uppercase;
  font-weight: bold;
}

.capitalize {
  text-transform: capitalize;
}

.capitalize-bold {
  text-transform: capitalize;
  font-weight: bold;
}

.padding-top-bottom-10 {
  padding: 10px 0;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-10 {
  padding: 10px;
}

.padding-20 {
  padding: 20px;
}

.margin-10 {
  margin: 10px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.align-self-center {
  align-self: center;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-around {
  justify-content: space-around;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.justify-content-normal {
  justify-content: normal;
}

.justify-content-end {
  justify-content: end;
}

.align-self-start {
  align-self: start;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-5 {
  flex-grow: 5;
}

.text-align-center {
  text-align: center;
}

.text-align-start {
  text-align: start;
}

.text-align-end {
  text-align: end;
}

.width-100 {
  width: 100%;
}

.background-white {
  background-color: white;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.height-100 {
  height: 100%;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.text-nowrap {
  white-space: nowrap;
}

.text-normal {
  white-space: normal;
}

.flex-auto {
  display: flex;
  flex: auto;
}

.font-bold {
  font-weight: bold;
}

.width-space {
  width: -webkit-fill-available;
  width: -moz-available;  /* Firefox web browser  */
}

.relative-position {
  position: relative;
}

.absolute-position {
  position: absolute;
}