.feedback-modal-fullscreen {
    overflow: hidden;
    height: 100%;
    padding-top: calc(env(safe-area-inset-top) - 30px);
    margin: 0;
    min-width: 100%;
    .ant-modal,
    .ant-modal-body {
        margin: 0;
    }

    textarea.ant-input {
        height: calc(40vh - 150px);
    }

    .rate-emoji {
        font-size: 41px;
        color: #3c61b4
    }

    .ant-rate-star:not(:last-child) {
        margin-right: 15px;
    }
    
    .ant-rate-star:first-of-type {
        margin-left: 3px;
    }
    
    .feedback-modal-header {
        font-size: 20px;
        padding-top: 15px;
    }
    
    .ant-form-item-label > label {
        font-size: 16px;
    }

    .feedback-ratings {
        margin-bottom: -15px !important;
    }

    .ant-form-item-control {
        margin-bottom: 15px;
    }

    /* ----------- Responsive screens -----------*/
    @media only screen and (max-width: 1600px) {

        .ant-form-item-control {
            margin-bottom: 0px;
        }
    
        .ant-rate.rate-emoji {
            display: flex;
        }

        .feedback-comments-margin {
            margin-top: 10px;
        }
    }

    /* ----------- iPhone 5, 5S, 5C and 5SE ----------- */
    @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {

        .ant-modal,
        .ant-modal-body {
            height: 100vh;
            margin: 0;
        }
    }

    /* ----------- iPhone 6, 6S, 7 and 8 -----------*/
    @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {

        .rate-emoji {
            font-size: 52px;
        }

        .ant-modal,
        .ant-modal-body {
            height: 100vh;
            margin: 0;
        }
   }

    /* ----------- iPhone 6+, 7+ and 8+ -----------*/
    @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {

        .rate-emoji {
            font-size: 60px;
        }
    }
}

.feedback-modal-desktop {
    min-width: 30%;
    height: 51%;

    .ant-form-item-label {
        text-align: left;
    }
}

.feedback-modal-header-desktop {
    font-size: 20px;
}
@primary-color: #3c61b4;