.recommendation-detail-container {
  width: 100%;
  overflow-y: auto;
}

.recommendation-detail-card1-container {
  display: flex;
  flex-direction: column;
  padding: 5px;
  font-size: 16px;
  align-items: baseline;
  cursor: var(--default-cursor);
  row-gap: 10px;
}

.recommendation-detail-card2 {
  display: flex;
  flex-direction: column;
  padding: 5px;
  font-size: 18px;
  align-items: baseline;
}

.recommendation-detail-card2-container {
  display: flex;
  flex-direction: column;
}

.recommendation-detail-card2-container .ant-switch-handle {
  height: 26px;
  width: 26px; 
}

.recommendation-detail-card2-container .ant-switch-handle::before {
  border-radius: 13px;
}

.recommendation-detail-card2-container .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 26px - 2px);
}

.recommendation-detail-card2-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  width: 100%;
  margin-bottom: 10px;
}

.recommendation-detail-card2-deficit {
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 16px;
  cursor: default;
}

.recommendation-detail-card2-vwc {
  display: flex;
  width: 50%;
}

.highcharts-axis-labels span { 
  word-break: break-all !important;
  width: 45px !important;
  white-space: normal !important;
}

.recommendation-comments-section {
  text-align: left;
  margin-left: -13px;
  margin-top: -12px;
}

.recommendation-comments-detail {
  display: flex;
  flex-direction: column;
  margin-top: -11px;
  margin-left: 8px;
}

.lan-long-align {
  font-size: 12px;
}

.recommendation-add-comment-section, .ant-modal-body {
  padding-top: 5px !important;
}

.recommendation-add-comment-upload {
  border: none;
}

.error-report-button {
  margin-bottom: -15px;
  margin-top: -10px;
}

.recommendation-map-button { 
  background-color: rgba(0,0,0,0.7) !important;
  color: white;
  border: none;
}

.custom-button-margin { margin-top: 5px; }

.recommendation-container .resizer-b {
  display: none;
}

.recommendation-irrigation-action-buttons {
  position: fixed;
  width: 100%;
  bottom: -2px;
  background-color: var(--desktop-recommendation-bg-color);
  z-index: 400;
  padding: 10px 5px !important;
  max-width: 880px;
  margin-left: -5px;
}

.irrigation-equation-table-border {
  border: 1px solid #ddd;
}

@media (max-width: 900px) {
  .recommendation-detail-actions {
    padding-top: 12px;
  }
}

@media (min-width: 450px ) and (max-width: 900px) {
  .recommendation-detail-actions {
    position: static;
  }

  .anomaly-details-container{
    padding-bottom: 0px;
  }
  
  .recommendation-anomaly-action-buttons{
    width: -webkit-fill-available;
  }
}

/* This table styles will be updated in future */
table {
   width:100%;
}

.tr-bold-text {
  font-weight: 600;
}

.anomaly-table {
  overflow: auto;
  cursor: var(--default-cursor);
  border: 1px solid #ddd;
}

.anomaly-table .table > thead > tr > th {
  min-width: 80px;
}

.anomaly-table table, th, td {
  border-collapse: collapse;
  font-size: 16px;
}

.anomaly-table td, .anomaly-table th {
  padding-left: 0px;
  word-wrap: break-word;
}

tr th:first-child {
  border-left: 10px !important;
}

.anomaly-view-tag {
  height: 20px;
  width: 30px;
}

.anomaly-view-select-width {
  min-width: 100px;
}

.table-controls-div {
  display: flex;
  width: 100%;
  margin-bottom: 5px;
  flex-wrap: wrap;
  column-gap: 10px;
}

.table-margin-left-10 {
  margin-left: 10px;
}

.label-red { color: red; }
.label-green { color: green; }
.label-bold { font-weight: bold; }

.anomaly-legends {
  position: absolute;
  font-weight: bold;
  color: var(--white-background);
  font-size: medium;
  margin-top: -2px;
}

/* For map anomaly severity legends on sidebar */
.desktop-sidebar-details .anomaly-legends {
  font-size: x-small;
  margin-top: 3px;
  margin-left: -13px;
}

.anomaly-square {
  height: 20px;
  width: -webkit-fill-available;
  width: -moz-available;
}

.anomaly-view-option {
  display: flex;
  align-items: center;
  height: 30px;
}

.anomaly-legends-container {
  position: relative;
  margin-right: -10px;
  cursor: var(--default-cursor);
}

.white-text-color {
  color: var(--default-text-color);
}

.anomaly-table-header-width {
  width: 60px;
}

.anomaly-area-calc-table-list-option .rc-virtual-list-scrollbar-thumb {
  width: 0px !important;
}
.bold-text {
  font-weight: 600;
  font-size: 16px;
}

.align-left {
  text-align: left;
  padding-left: 10px !important;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
  padding-right: 15px !important;
}

.tab-content .ant-tabs-tab {
  display: block; 
  flex: 1;
  text-align: center;
  font-size: 16px;
  color:#808080;
}
  
.tab-content .ant-tabs-nav > div:nth-of-type(1) {
  display: unset;
  width: 100% !important;
}

.tab-content .ant-tabs-nav-operations {
  display: none !important;
}

.tab-content .ant-tabs-nav{
  overflow: hidden;
}

#irrigation-recommendation-detail.recommendation-container {
  height: fit-content !important;
}

@media only screen and (min-width: 450px) and (max-width: 1100px){
  #irrigation-recommendation-detail.recommendation-container {
    width: -webkit-fill-available;
  }
}

.recommendation-text-line-height {
  line-height: 20px;
}

.recommendation-generated-line-height {
  line-height: 15px;
}

.text-margin {
  margin-left: 3px;
}

#irrigation-recommendation-detail .mobile-list-item {
  overflow: visible;
}

/* ----------- iPhone 5, 5S, 5C and 5SE -----------*/
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .anomaly-legends {
    font-weight: bold;
    font-size: x-small;
    margin-left: -10px;
    margin-top: 4px;
  }

  .tab-content .ant-tabs-nav > div:nth-of-type(1) {
    display: flex;
  }
}

@media only screen and (max-width: 1700px) {
  .recommendation-container .mobile-list-item {
    margin-bottom: 10px;    
  }
}

/* Below style properties will be updated */
.table-tooltip {
  position: relative;
  display: block;
  cursor: pointer;
}

/* Tooltip text */
.table-tooltip .tooltiptext {
  visibility: hidden;
  width: 150%;
  background-color: #FAFAFA;
  border: 1px solid black;
  color: black;
  text-align: left;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 50%;
  transform: translateX(-80%);
  opacity: 0;
  transition: opacity 0.3s;
  white-space: normal;
  word-wrap: break-word;
  font-size: 15px;
}

/* Show the tooltip text when hovering or tapping */
.table-tooltip:hover .tooltiptext,
.table-tooltip:active .tooltiptext {
  visibility: visible;
  opacity: 1;
}

@media only screen and (min-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {

  .table-tooltip .tooltiptext {
    min-width: 340px;
    font-size: 14px;
  }
}