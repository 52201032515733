@import "../app/colors.less";

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
        0deg,
        darken(@background, 20%),
        @background,
    );
}

.login-container {
    margin: 0;
    background-color: transparent;
    height: 100%;
    margin-top: 10vh;
}

.login-container .ant-form-item {
    margin-bottom: 12px;
}

.login-container .login-logo {
    height: 95px;
    width: 248px;
}

.login-container .login-link {
    color: white;
    padding-left: 0px;
    flex: 50%;
    text-align: left;
    height: 50px;
    align-items: center;
    display: flex;
}

.login-container .ant-input-affix-wrapper {
    border-radius: 10px;
}

.login-container .ant-input {
    font-size: 18px;
    border-radius: 25px;
    height: 40px;
}

.login-container .ant-form-item .ant-input {
    border: none;
}

.login-container .ant-input-prefix {
    margin-right: 10px;
    font-size: 20px;
}

.login-container .ant-input-password-icon {
    font-size: 20px;
}

.login-container .ant-form-item-label > label {
    font-size: 16px;
    color: white;
}

.login-container .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}

.login-container .submit-form-item {
    margin-top: 24px;
}

.submit-form-item .ant-form-item-control-input-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
}

.login-container .login-form-button {
    background: @secondary;
    border-color: @secondary;
    font-weight: bold;
    font-size: 18px;
    height: 50px;
    flex: 50%;
}

.login-container .ant-divider-horizontal {
    min-width: 40%;
    width: 40%;
    background-color: @secondary;
    height: 4px;
    margin: 30px auto 20px auto !important;
}

@media only screen and (max-width: 350px) {
    .login-container .login-logo {
        height: 63.3px;
        width: 165px;
    }
}

@media (orientation: portrait) and (max-height: 700px) {
    .mobile  .login-container .ant-divider-horizontal {
        display: none;
    }

    .mobile .login-container {
        overflow-y: auto;
    }
}

@media (orientation: landscape) and (max-height: 600px) {
    .mobile {
        background: @background;
    }

    .mobile .login-container {
        margin-top: auto;
        display: flex;
        justify-content: center;
    }

    .mobile .login-container .login-logo {
        display: none;
    }

    .mobile .login-container .ant-divider {
        display: none;
    }
}

@primary-color: #3c61b4;