  .fullScreen-button-section {
      margin-left: 20px;
  }

  .screen-button-section {
    background-repeat: no-repeat;
    width: 200px;
    height: 110px;
    cursor: pointer;
  }
  
  .fullScreen-selected {
    background-image: url(../../../assets/images/fullscreen_selected.png);
  }
  
  .fullScreen {
    background-image: url(../../../assets/images/fullscreen.png);
  }
  
  .splitScreen-button-section {
    margin-left: -20px;
  }
  
  .splitScreen {
    background-image: url(../../../assets/images/splitscreen.png);
  }
  
  .splitScreen-selected {
    background-image: url(../../../assets/images/splitscreen_selected.png);
  }

  .mobile-left-button {
    padding-left: 20px;
  }

  .mobile-right-button {
    padding-right: 13px;
  }
@primary-color: #3c61b4;