.cutoff-points-input {
  & .ant-input-number {
    width: 170px;
  }

  & .input-wrap {
    position: relative;

    & .extend-input {
      background: white;
      position: absolute;
      width: 100px;
      right: 2px;
      font-size: 14px;
      margin-top: 5px;
    }
  }
}

@primary-color: #3c61b4;