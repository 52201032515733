.breadcrumb-select-container {
  .left-align {
      float: left;
  }

  .right-align {
    float: right;
  }

  .add-color-to-even-rows {
    height: 30px;
    line-height: 30px;
  }

  .left-options {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(50% - 10px);
    overflow: hidden;
    display: block;
  }

  .right-options {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 50%;
    display: block;
    overflow: hidden;
    text-align: end;
  }

  .ant-dropdown {
    max-height: 330px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }

  .ant-dropdown-menu-item li , .ant-dropdown-menu-submenu-title {
    width: calc(20vw - 10px) !important;
  }

  .ant-breadcrumb  li , .ant-breadcrumb-separator {
    color: var(--white-background) !important;
  }
}

.ant-dropdown-menu {
  max-height: 300px;
  overflow-y: scroll;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-dropdown-menu::-webkit-scrollbar {
  width: 0px;
}

.breadcrumb-select-container-width {
  margin-left: 108px;
}

.ant-dropdown .breadcrumb-select-container {
  min-width: 300px !important;
}

.breadcrumb-select-container nav {
  cursor: var(--pointer-cursor);
}
@primary-color: #3c61b4;