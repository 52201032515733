.ranch-block-settings {
  padding: 20px;
  padding-left: 0px;
  padding-right: 0px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #d9d9d9; /* Add border */
  padding-bottom: 10px; /* Space between content and border */
}

.ranch-title {
  margin: 0;
}

.header-buttons {
  display: flex;
  gap: 10px;
}

.follow-all-button, .unfollow-all-button {
  border-radius: 15px;
}

.block-item {
  background-color: #f3f3f3; /* Grey background */
  margin-bottom: 10px; /* Space between items */
  padding: 5px; /* Padding inside items */
  padding-left: 0px;
  padding-right: 0px;
  border-radius: 5px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.075);
}

.block-button {
  border-radius: 20px;
  padding: 0 20px;
  font-size: 16px;
}
