@primary: #3c61b4;
@secondary: #e35b29;
@background: @primary;
@select-border-color: rgb(217, 217, 217);
@background-color: #dddddd;

@font-color-light: white;
@font-color-dark: #333333;

@color-state-unknown: #989898;
@color-state-over: #1890ff;
@color-state-ok: rgb(61, 200, 115);
@color-state-warning: #faad14;
@color-state-error: #F26F30;
@color-state-critical:  #e8101e;
@color-state-not-irrigating: whitesmoke;
@color-state-disease: #000000;
@color-state-missing: #3dc873;
@color-state-irrigation: #429aff;
@irrigation-equation-label-text-color: #888;
@dhtmlx-scheduler-current-date-color :#e0e0e0;
@recommendation-map-background-color: #aaa;
@light-black: rgba(0, 0, 0, 0.7);
@map-compass-black-color: rgba(0, 0, 0, 0.85);
@desktop-bg-color: #F0F2F5;
@light-gray: #ECECEC;

@offline: '#bfbfbf';
@error2: '#ffa39e';
@warning2: '#ffe58f';
@success2: '#b7eb8f';
@standby2: '#91d5ff';
@offline2: '#f5f5f5';

@lighten-percent: 0%;

:root {
    --dangerous-button-background: @color-state-critical;
    --disabled-button-background: @offline2;
    --disabled-button-border: @select-border-color;
    --white-background: @font-color-light;
    --active-tab-background-color: @primary;
    --active-tab-text-color: @font-color-light;
    --default-text-color: @color-state-disease;
    --background-color : @background-color;
    --scrollbar-color: @color-state-unknown;
    --login-button-color: @secondary;
    --irrigation-equation-label-text: @irrigation-equation-label-text-color;
    --menu-blue-color: @color-state-over;
    --current-date-color: @dhtmlx-scheduler-current-date-color;
    --recommendation-map-bg-color: @recommendation-map-background-color;
    --light-background-black-color: @light-black;
    --map-compass-background-color: @map-compass-black-color;
    --desktop-recommendation-bg-color: @desktop-bg-color;
    --light-gray: @light-gray;
}

.color-state-unknown {
    background-color: @color-state-unknown;
    color: @font-color-dark;
    fill: @color-state-unknown;
}

.color-state-over {
    background-color: @color-state-over;
    color: @font-color-light;
    fill: @color-state-over;
}

.color-state-ok {
    background-color: @color-state-ok;
    color: @font-color-light;
    fill: @color-state-ok;
}

.color-state-warning {
    background-color: @color-state-warning;
    color: @font-color-dark;
    fill: @color-state-warning;
}

.color-state-error {
    background-color: @color-state-error;
    color: @font-color-light;
    fill: @color-state-error;
}

.color-state-critical {
    background-color: @color-state-critical;
    color: @font-color-light;
    fill: @color-state-critical;
}

.color-state-not-irrigating {
    background-color: @color-state-not-irrigating;
    color: @font-color-dark;
    fill: @color-state-not-irrigating;
}

.color-state-disease {
    background-color: @color-state-disease;
    color: @font-color-light;
    fill: @color-state-disease;
}

.color-state-missing {
    background-color: @color-state-missing;
    color: @font-color-dark;
    fill: @color-state-missing;
}

.color-state-irrigation {
    background-color: @color-state-irrigation;
    color: @font-color-light;
    fill: @color-state-irrigation;
}

.text-color-normal {
    color: black;
}

.text-color-unknown,
.ant-typography.text-color-unknown {
    color: @color-state-unknown;
}

.text-color-over {
    color: @color-state-over;
}

.text-color-ok {
    color: @color-state-ok;
}

.text-color-warning {
    color: @color-state-warning;
}

.text-color-error {
    color: @color-state-error;
}

.text-color-critical {
    color: @color-state-critical;
}
