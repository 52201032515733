.plant-selection-options {
    margin-left: -6px;
    margin-top: 5px;

    .ant-checkbox-wrapper{
        margin-left: 7px;
    }

    .ant-checkbox-group {
        margin-top: 5px;
        margin-left: 2px;
    }

    .ant-divider-horizontal {
        margin: 5px 0px;
    }

    .ant-checkbox-group span {
        text-transform: capitalize;
    }
}

/* ----------- iPhone 5, 5S, 5C and 5SE -----------

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {

    .plant-selection-options {
        .ant-checkbox-wrapper {
            margin-left: 0px !important;
            margin-bottom: 5px;
        }
    
        .ant-checkbox-group {
            margin-left: 2px !important;
            display: flex;
            flex-wrap: wrap;
        }

        .ant-checkbox-group .ant-checkbox span {
            margin-top: -8px !important;
        }

        .ant-checkbox-wrapper .ant-checkbox span {
            margin-top: -8px !important;
        }
    }
  }
@primary-color: #3c61b4;