.resizeable {
  position: relative;
  min-height: 15px;
  /* height: 200px; */
}

.resizer {
  position: absolute;
  background: lightgray;
}

.resizer-b {
  cursor: row-resize;
  height: 5px;
  left: 0;
  bottom: -5px;
  width: 100%;
}
