.valve-sensor-chart-container {
  padding: 20px 0;
  .chart-container-wrapper {
    height: 250px;
    @media screen and (max-width: 720px) {
      height: 200px;
    }
  }
}

@primary-color: #3c61b4;