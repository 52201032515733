.container {
    width: 100%;
    min-height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:2.5rem;

.pass-container {
    width: 30rem;
    height: auto;
    border-radius: 0.5rem;

    perspective: 600px;
    height: auto;
    font-size: 16px;
    overflow: hidden;
    .inner {
        position: relative;
        width: 30rem;
        height: 100%;
        text-align: center;
        background-color:#eeeeee;
        padding: 1rem 2rem;
        border-radius: 0.5rem;
        transition: transform 0.8s;
        transform-style: preserve-3d;
    }

}

}


.pass-container .inner .res-pass-form {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    font-size: 16px;

}

.pass-container.is-flipped .inner {
    transform: rotateY(180deg);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flip-card-back {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }

  .res-pass-form{
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
.flip-card-back {
    transform: rotateY(180deg);
}
.pass-container .ant-form-item {
   margin-top: 0.5em;
}

.pass-container .res-pass-button {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    font-size: 16px;
 }



.pass-container .login-message {
    text-align: start;
    color: rgb(85, 127, 165);
}

.pass-container {
.ant-input {
    height: 30px;
    outline: none;
    border: none !important; 
    &:focus{
        outline: none;
        background-color: white;
    }
  }
}
.container .header-logo {
    height: 95px;
    width: 248px;
}

.pass-container .res-pass-form .ant-btn-primary, 
.pass-container .res-pass-form .ant-btn-primary:hover {
    background: var(--login-button-color) !important; 
    border-color: var(--login-button-color) !important;
}

.res-pass-form .ant-input-affix-wrapper{
    border-radius: 12px;
    font-size: 16px;

}

@media only screen and (max-width: 600px) {
    .container {
        background-color:#eeeeee;
        height: 100%;
        gap: 0px;
        border-radius: 0;

    }

    .pass-container,.inner {
        height: 100% !important;
        width: 100% !important;
        border-radius: 0px !important;
        
    }
}

@primary-color: #3c61b4;