.flex-column-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.flex-title-container {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.scrollable-content {
  flex: 10;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto; /* This allows vertical scrolling without always showing the scrollbar */
  align-content: center;
  padding: 1px;
}

/* Hides the scrollbar for Webkit browsers like Chrome and Safari */
.scrollable-content::-webkit-scrollbar {
  display: none;
}

/* For Firefox, to hide the scrollbar */
.scrollable-content {
  scrollbar-width: none; /* Firefox */
}


.flex-title-content {
  flex: 10;
  align-content: center;
}

.flex-action-button-content {
  flex: 1;
  align-content: center;
}

/* General styles for the TeamsList component */
.teams-list-container {
  font-family: 'Proxima Nova', sans-serif;
  color: #3c61b4;
}
.organization-table {
  font-family: 'Proxima Nova', sans-serif;
  
  
  /* Table customizations */
  .ant-table-wrapper {
    /* Remove border radius and box shadow */
    border-radius: 0;
    box-shadow: none;
  }
  
  .ant-table-thead > tr > th {
    font-weight: bold; /* Bold text */
    background-color: white;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #3c61b4;
  }

  .ant-btn-primary {
    background-color: #3c61b4;
    border-color: #3c61b4;
    font-weight: bold;
  }

  /* Rounded buttons */
  .ant-btn {
    border-radius: 20px;
  }

  /* Custom tooltip */
  .ant-tooltip-inner {
    font-family: 'Proxima Nova', sans-serif;
    background-color: #3c61b4;
    color: #ffffff;
  }

  /* Pagination styles */
  .ant-pagination-item-active {
    background-color: #e8e9eb;
    border-color: #3c61b4;
  }
  .ant-pagination-item-link {
    color: #3c61b4;
  }

  .ant-transfer {
    width: 100%;
  }
  
  .ant-transfer {
    .ant-btn-primary{
      background: #f5f5f5;
      border-color: #d9d9d9
    }  
  }

  .custom-transfer .ant-transfer-operation .ant-btn:not(.ant-btn-disabled) .anticon {
    color: #3c61b4;
  }

  tr th:first-child {
    border-left: none;
  }

  @media (max-width: 600px) {
    .ant-table-column {
      padding: 8px;
    }
  }
}
