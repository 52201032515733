@header-color: #dedede;

.soil-moisture-depth-chart {
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: 16px;
    background-color: white;
    height: 100%;
}

.soil-depth-column {
    display: flex;
    flex: 1 1;
    flex-direction: column;
}

.soil-types-column {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.soil-moisture-column {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.soil-column-header {
    flex: 1;
    font-weight: 600;
    border-bottom: 1px solid #333;
    display: flex;
    align-items: center;
    background-color: @header-color;
    padding: 10px;
    max-height: 30px;
}

.soil-data-cell {
    padding: 5px 10px;
    flex: 1;
    align-items: center;
    display: flex;
    max-height: 30px;

}

.soil-data-cell .ant-tag {
    justify-content: center;
    display: flex;
    width: 100%;
    font-size: 16px;
    text-transform: capitalize;
    align-items: center;
    height: 25px;
}

@primary-color: #3c61b4;