.sensor-detail-header {
  width: 100%;
  justify-content: space-between;
  cursor: var(--default-cursor);

  .sensor-header-icon-block {
    padding-top: 4px;
    margin-right: 10px;
    margin-left: -8px;

    i {
      font-size: 38px;
    }
  }
  .sensor-header-name {
    font-size: 24px;
    padding-top: 5px;
  }

  .sensor-header-alignment {
    float: left;
  }

  .sensor-header-name-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    text-align: left;
  }

  .sensor-header-type {
    font-size: 20px;
    text-align: left;
  }

  .mobile-sensor-header-align {
    margin-top: -10px;
  }

  .sensor-header-location {
    font-size: 16px;
    color: #0D58B7;
  }

  .sensor-header-alarms {
    font-size: 24px;
    padding-top: 4px;
  }

  .sensor-header-alarms-bell {
    font-size: 28px;
  }

  .sensor-header-status {
    font-size: 12px;
  }

  .sensor-alarm-status-block {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    justify-content: center;
  }
  .flex-column.width-100 .flex-row.margin-bottom-10 {
    justify-content: space-between;
  }
}

@primary-color: #3c61b4;