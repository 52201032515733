:root {
  --width-100: 100%;
  --recommendation-map-container-height: 450px;
}

.home-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 15px;
}

.home-container .ant-card {
  border-radius: 5px;
  border: 1px solid #ddd;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
  width: 100%;
}

.home-container .ant-card-head,
.home-container .ant-card-body {
  padding-left: 10px;
  padding-right: 10px;
}

.home-container .ant-tag {
  margin-right: 0px;
  height: 30px;
  font-size: 16px;
  border-radius: 5px;
  align-items: center;
  display: flex;
  cursor: var(--default-cursor);
}

.home-container .ant-card-head-wrapper {
  column-gap: 10px;
}

.home-container .ant-card-head-title,
.home-container .ant-card-extra,
.home-container .ant-card-body {
  padding-top: 10px;
  padding-bottom: 10px;
}

.home-container .ant-card-head {
  border-bottom: 1px solid #ddd;
  border-radius: 5px 5px 0px 0px;
  background-color: #fafafa;
}

.home-container .ant-card-head-title {
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: var(--default-cursor);
}

.recommendation-list-item-content {
  margin-bottom: 15px;
}

.recommendation-list-item-content .recommendation-description,
.recommendation-more-button button {
  font-size: 16px;
  cursor: var(--default-cursor);
}

.recommendation-description span{
  cursor: var(--pointer-cursor);
}

.home-container .recommendation-list, .ant-card-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1
}

.home-container .ant-card-actions {
  border-top: none;
  margin-left: 10px;
  margin-right: 10px;
}

.home-container .ant-card-actions > li {
  margin: 0px;
  margin-bottom: 10px;
}

.recommendation-list-item-footer,
.action-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  column-gap: 15px;
  width: inherit;
}

.action-container-primary {
  flex: 65;
}

.action-container-secondary {
  flex: 35;
}

.am-wingblank.am-wingblank-lg {
  margin-left: 10px;
  margin-right: 10px;
}

.recommended-scrollable-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.irrigation-equation-container .equation-message {
  padding: 10px;
}

.vwc-chart-icon {
  height: 50px;
  width: 50px;
  border-radius: 20px;
  cursor: pointer;
  touch-action: none;
}

#draggableIcon {
  width: 50px;
  overflow: visible !important;
  backface-visibility: hidden;
  touch-action: none;
}

.recommendation-more-button>button {
  padding: 0px;
  border: none;
}

.preference-model-sections-header{
  font-size: large;
  font-weight: 700;
  cursor: var(--default-cursor);
}

.preference-model-sections-header span, p{
  cursor: var(--default-cursor);
}

.preference-modal-settings-icon {
  padding-right: 10px;
  padding-top: 3px;
}

.preference-button:hover,
.preference-button:focus,
.preference-button:active {
  color: black;
  border-color: white;
  box-shadow: none;
}

.column-gap-10 {
  column-gap: 10px;
}

.desktop-imagery-anomaly .anomaly-details-container 
.recommendation-detail-card2-top.map-anomaly-graph-buttons {
  display: none;
}

.recommended-scrollable-container .ant-radio-group {
  float: left;
}

@media only screen and 
(max-width: 1200px) and 
(min-height: 900px) { 
  .recommended-scrollable-container .ant-radio-group {
    float: left;
    margin-left: 2px;
  }

  .recommended-scrollable-container .ant-radio-button-wrapper {
    padding: 0px 16px;
  }
}

@media only screen and 
(max-width: 1000px) and 
(min-height: 900px) { 
  .recommended-scrollable-container .ant-radio-group {
    float: inherit !important;
    display: flex !important;
    justify-content: space-between !important;
  }

  .recommended-scrollable-container .ant-radio-button-wrapper {
    padding: 0 6px;
    width: 100% !important;
    text-align: center;
  }
}

@media (min-width: 900px) {

  .home-container {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)) ;
  }

  .page-container {
    width: 900px;
    height: fit-content;
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 15px;
  }

  .action-footer {
    width: initial;
  }
}

.home-page-pressure-sensor-details .recommendation-detail-card2-top {
  padding-top: 10px;
}

.anomaly-details-container div.controls-right {
  top: 115px;
  right: 16px;
}

.anomaly-details-container .leaflet-touch .leaflet-bar a {
  width: 38px !important;
  height: 40px !important;
  line-height: 40px !important;
}

.anomaly-details-container .leaflet-top {
  top : -4px;
}

.status-tag-icon-alignment {
  padding-top: 2px;
}

.lat-lng-select-options.ant-btn-background-ghost.ant-btn-primary {
  color: var(--white-background) !important;
}

.lat-lng-select-options.ant-btn-background-ghost.ant-btn-primary:hover {
  color: var(--menu-blue-color) !important;
}

.anomaly-details-container .maplibregl-ctrl-zoom-in.mapboxgl-ctrl-zoom-in, 
.anomaly-details-container .maplibregl-ctrl-zoom-out.mapboxgl-ctrl-zoom-out {
  display: none;
}

.recommendation-container .mapboxgl-ctrl-group button.mapboxgl-ctrl-compass,
.recommendation-container .mapboxgl-ctrl-group button.mapboxgl-ctrl-compass:hover {
  background-color: var(--map-compass-background-color) !important;
  border-radius: 3px;
}

.recommendation-container .mapboxgl-ctrl-group button+button, .maplibregl-ctrl-group button+button {
  border-top: none;
}

.recommendation-container .mapboxgl-ctrl button .mapboxgl-ctrl-compass .mapboxgl-ctrl-icon, .maplibregl-ctrl button.maplibregl-ctrl-compass .maplibregl-ctrl-icon {
  background-image: url('data:image/svg+xml;charset=utf-8,<svg width="29" height="29" viewBox="0 0 29 29" xmlns="http://www.w3.org/2000/svg" fill="red"><path d="M10.5 14l4-8 4 8h-8z"/><path d="M10.5 16l4 8 4-8h-8z" fill="%23ccc"/></svg>');
}

.plant-drone-map-height {
  height: 360px;
  min-height: var(--recommendation-map-container-height);
  background-color: var(--recommendation-map-bg-color);
}

.anomaly-map-height {
  height: 250px;
  background-color: var(--recommendation-map-bg-color);
}

.desktop-recommendation-map-container-height {
  min-height: var(--recommendation-map-container-height); 
}

.anomaly-details-container div.controls-right.plant-drone-options {
  top: 85px !important;
  right: 25px !important;
}

.ground-mission-action div.controls-right.plant-drone-options {
  top: 110px !important;
}

.plant-drone-map-height .ant-btn-icon-only > .anticon {
  display: grid;
  justify-content: center;
}

.left-next-buttons {
  position: absolute;
  top: 47%;
  left: 1%;
}

.right-next-buttons {
  position: absolute;
  top: 44%;
  right: 3px;
}

.left-next-buttons button span, 
.right-next-buttons button span {
  color: var(--default-text-color);
  font-size: larger;
}

.left-next-buttons button, 
.right-next-buttons button {
  width: 40px;
  height: 40px;
  margin-right: 7px;
  border-radius: 5px;
  background: var(--white-background) !important;
  border-color: var(--white-background) !important;
}

.ground-mission-header {
  margin-left: 0px;
}

.ground-mission-select-imagery-type {
  position: absolute;
  top: 30px;
  left: 30px;
}

.ground-mission-date-picker {
  position: absolute;
  top: 80px;
  left: 31px;
}

.ground-mission-date-picker .imagery-date-picker {
  background-color: var(--light-background-black-color) !important;
  height: 40px;
  border-radius: 5px;
  border-color: var(--light-background-black-color) !important;
  width: 111px;
}

.ground-mission-date-picker .ant-picker-suffix,
.ground-mission-date-picker .ant-picker-input > input,
.ground-mission-date-picker .ant-picker-input > input::placeholder {
  color: var(--white-background) !important;
  margin-left: -3px;
}

.ground-mission-recenter-button {
  top: 10px;
}

.recommendation-container .ant-select-single.ant-select-show-arrow .ant-select-selection-item  {
  padding-right: 30px !important;
}

.ground-mission-select-suffix-icon {
  color : var(--white-background)
}

.ground-mission-action .recommendation-detail-card2-container .recommendation-detail-card2-top {
  margin-bottom: 0px !important;
  height: 0px !important;
}

.anomaly-details-container {
  padding-bottom: 135px;
}

.desktop-imagery-anomaly .anomaly-details-container {
  padding-bottom: 0px;
}

/* Select-filter-dropdown desktop classname */
.recommendation-filter-type {
  display: flex;
  column-gap: 15px;
  align-items: baseline;
  margin-top: 4px;
}

.tabs-space {
  display: flex;
  gap: 10px;
}

.filter-dropdown-min-width {
  width: 120px;
}

/* ----------- iPhone 5, 5S, 5C and 5SE -----------*/
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
 
   div.controls-right.anomaly-detail-layer {
    top: 28px;
    right: 15px !important;
  }  

  .plant-drone-options {
    top: 85px !important;
    right: 24px !important;
  }

  .anomaly-map-height .ant-btn-icon-only > .anticon {
    display: grid;
    justify-content: center;
  }

  .map-leaflet.mapboxgl-map.maplibregl-map .left-next-buttons,
  .map-leaflet.mapboxgl-map.maplibregl-map .right-next-buttons {
    position: absolute !important;
    bottom: 10px;
    top: 80%;
    right: auto;
  }

  .negative-margin-left-10 {
    margin-left: 10px;
    top: 79% !important;
  }

  .left-next-buttons button,  
  .right-next-buttons button {
    margin-right: 5px;
  }

  .recommendation-detail-text {
    line-height: 15px;
  }

  .ground-mission-select-imagery-type {
    position: sticky !important;
    left: 0px;
    width: var(--width-100);
  }

  .ground-mission-date-picker {
    position: sticky !important;
    margin-left: 10px;
    width: var(--width-100);
  }

  .ground-mission-select-imagery-type .map-view-select,
  .ground-mission-date-picker .imagery-date-picker {
    width: var(--width-100) !important;
  }

  /* Select-filter-dropdown mobile classname */
  .recommendation-filter-type {
    display: flex;
    align-items: baseline;
    margin-top: 10px;
    margin-bottom: -5px;
    gap: 5px;
  }

  .tabs-space {
    display: inline-grid;
    gap: 5px;
  }

  .filter-type-text {
    min-width: calc(100vw - 90vw);
    font-size: medium;
    margin-left: 2px;
  }
}

/* ----------- for zoom level more than 100% -----------*/
@media (max-width: 1800px) and 
(min-width: 600px) {

  .action-footer {
    width: initial;
    padding-top: 10px;
  }

  .page-container {
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 15px;
  }
}

@media (min-width: 320px) and (max-width: 750px) {
  .recommended-scrollable-container .ant-radio-group {
    float: inherit;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 760px) and (max-width: 1700px) {
 .recommended-scrollable-container .ant-radio-group {
    float: inherit;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1810px) {
  .page-header {
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 1700px) and (max-width: 1800px) {
 .page-tab-container {
      margin-bottom: 10px;
    }
  }
