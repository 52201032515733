@import "../../app/colors.less";

@header-color: @primary;
@header-height: 60px;

:root {
  --header-height: @header-height;
}

html {
  min-height: calc(100% + env(safe-area-inset-top));
  margin-top: env(safe-area-inset-top);
}

html,
body,
#root,
.layout-mobile {
    background-color: @background;
    width: 100%;
}

.layout-mobile header {
  height: @header-height;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0px;
  background-color: @header-color;
  z-index: 1001;
}

.layout-mobile .main-menu {
  line-height: 50px;
}

.layout-mobile .logo {
  justify-self: center;
  flex-grow: 1;
  text-align: center;
}

.mobile-header-logo {
  width: 95px;
  height: 43px;
}

.mobile-menu-trigger {
  height: @header-height;
  width: @header-height;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout-mobile .trigger {
  color: 'white';
  font-size: 30px;
}

.layout-mobile .notification-dropdown {
  height: @header-height;
  align-items: center;
  justify-content: center;
}

.layout-mobile .raf-icon-badge {
  height: @header-height;
  width: @header-height;
}

.layout-mobile main {
  display: flex;
  flex-direction: column;
  min-height: calc(100% - @header-height);
}

.layout-mobile .bottom-tabbar-container {
  margin-bottom: calc(env(safe-area-inset-bottom) + env(safe-area-inset-top));
}

.layout-mobile .main-content {
  background-color: @background-color;
  flex: 1 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

@media (orientation: landscape) {
  .layout-mobile header {
      padding-left: env(safe-area-inset-left);
      padding-right: env(safe-area-inset-right);
  }

  .layout-mobile .bottom-tabbar-container {
    margin-bottom: env(safe-area-inset-bottom);
  }

  .layout-mobile .main-content {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}

@primary-color: #3c61b4;