.pass-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 2.5rem;
    overflow-y: auto;
    
    .inner-container {
        background-color: #eeeeee;
        padding: 1rem 2rem;
        text-align: center;
        border-radius: 1rem;
        width: 30rem;
        overflow-y: hidden;
        overflow-x: hidden;
    }
}

.ant-input-password {
    border-radius: 10px;
}

.pass-container .header-logo {
    height: 95px;
    width: 248px;
}
.new-pass-form {
    font-size: 16px;
    .ant-form-item-label > label {
        font-size: 16px;
    }
    .ant-form-item-explain-error{
        text-align: left;
    }
}
.pass-container .new-pass-form .ant-btn-primary, 
.pass-container .new-pass-form .ant-btn-primary:hover {
    background: var(--login-button-color) !important; 
    border-color: var(--login-button-color) !important;
}
.ant-form-item-control-input{
    text-align: left;
}
.blue{
    color: blue;
}

@media only screen and (max-width: 600px) {
    .pass-container {
        background-color: #eeeeee;
        height: 100%;
        gap: 0px;
        border-radius: 0;
    }
    .inner-container {
        height: 100%;
        width: 100% !important;
        border-radius: 0px;
    }
    .pass-message h1 {
        font-size: 32px !important;
        text-align: left;
    }

}
@primary-color: #3c61b4;