.rule-settings-table {
    .ant-table-thead .ant-table-cell {
        font-weight: bold;
        font-size: small;
        min-width: 100px;
    }
    td {
        font-size: small;
    }
    .ant-switch-inner {
        font-size: 14px;
    }
}

.rule-setting-form-title {
    width: 100%;
    margin: auto;
    text-align: center;
    margin-left: -40px;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn-align-end {
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-end;
}

.btn-align-start {
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-start;
}

.font-bold { font-weight: bold;}

.margin-15 { grid-column-gap: 15px;}

.form-item-width-100 { width: 100%;}

.margin-left-5 { margin-left: 5px;}

.margin-left-10 { margin-left: 10px;}

.font-size-16 { font-size: 16px; }

.repeat-interval {
    width: 60px !important;
}

.repeat-frequency {
    width: 80px !important;
}

.repeat-end {
    width: 90px !important;
}

.repeat-end-date {
    flex-grow: 1 !important;
    margin-top: 10px;
}

.repeat-end-count {
    flex-grow: 1 !important;
    margin-top: 10px;
    width: 200px;
}

.form-action-buttons {
    position: fixed;
    width: 100%;
    bottom: -20px;
    background-color: var(--desktop-recommendation-bg-color);
    z-index: 400;
    padding: 10px 5px !important;
    max-width: 880px;
    margin-left: -5px;
}

.margin-bottom-70 { margin-bottom: 70px;}

.width-80 { width: 80px !important;}

.width-100 { width: 100px;}

.flex-row-center {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.justify-center { justify-content: center;}

.page-header-main {
    width: 100%;
}

.rule-setting-form {
    width: 30vw;
    margin: auto;
}

.custom-width {
    width: 30vw;
    margin: auto !important;
}

.margin-inherit {
    margin: inherit !important;
}

.height-max { min-height: max-content;}

.width-20 { width: 20%;}

.width-100-percent { width: 100% !important;}

.ant-picker-panel-container { overflow: auto;}

@media (max-width: 768px) {
    .desktop-container .page-header {
        width: 100%;
    }
    
    .ant-picker-time-panel-column {
        overflow-y: auto !important;
    }

    .rule-settings-table {
        .ant-switch {
            height: 25px !important;
            width: 53px !important;
        }
        
        .ant-switch-inner {
            font-size: 13px !important;
        }

        .ant-switch-handle {
            height: 20px !important;
            width: 20px !important;
        }

        .ant-switch-checked .ant-switch-handle {
            left: calc(100% - 24px) !important;
        }

        tr th:first-child {
            padding-left: 16px;
        }
    }
}

@media only screen and (min-device-width: 320px) 
  and (max-device-width: 428px) 
  and (-webkit-device-pixel-ratio: 2),
  (-webkit-device-pixel-ratio: 3) {
  /* Styles for all iPhones */
  .flex-space-between {
    .ant-typography {
        font-size: 22px !important;
    }
  }
}
@primary-color: #3c61b4;