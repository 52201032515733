@import "../../app/colors.less";

.refill-point-form {
    font-size: 16px;
    row-gap: 10px;
}

.sensor-settings-page .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
    color: #555;
    font-size: 16px;
}

.sensor-settings-page .flex-row {
    align-items: center;
}

.sensor-settings-page {
    & .ant-btn {
        margin: 10px 0px;
        height: 40px;
    }

    & .soil-sensor-help-button.ant-btn {
        padding: 0;
        margin: 0;
        margin-left: 10px;
    }

    & .moisture-cut-off-title {
        display: flex;
        align-items: center;
    }

    & .sensor-settings-radio .ant-radio-button-wrapper-checked {
        color: white;
        background: @color-state-over;
        border-color: @color-state-over;
    }

    & .ant-input-number {
        width: 170px;
    }

    & .input-wrap {
        position: relative;

        & .extend-input {
            background: white;
            position: absolute;
            width: 100px;
            right: 2px;
            font-size: 14px;
            margin-top: 5px;
        }
    }

    #sensor-settings-button-style {
        height: auto !important;
        margin-top: -5px !important; 
    }

}

.modal-css() {
    & button.ant-modal-close {
        background: black;
        color: white;
        border: 3px solid white;
        border-radius: 100%;
        right: -10px;
        top: -14px;
        width: 40px;
        height: 40px;
    }

    & .ant-modal-header {
        padding-top: 24px;
        border-bottom: none;

        & .ant-modal-title {
            font-size: 22px;
        }
    }

    & .ant-modal-body {
        padding: 15px 24px;

        & .ant-typography {
            font-size: 18px;
        }
    }

    & span.ant-modal-close-x {
        line-height: initial;
        height: auto;
        width: auto;
        font-size: 17px;
    }
}

.moisture-cut-off-modal {
    & .state-legend-description {
        font-size: 16px;
        text-align: center;
        display: block;
    }

    .modal-css();
}

.sensor-settings-update-modal {
    .modal-css();

    & .update-item-heading {
        font-weight: bold;
        width: 135px;
        display: inline-block;
    }

    & .separator {
        margin: 0 15px;
    }
}

#select-component-wrapper .ant-select-selector {
    cursor:  var(--pointer-cursor)
}
@primary-color: #3c61b4;