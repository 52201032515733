.soil-state-legend-modal {
  & .state-legend-description {
    font-size: 16px;
    text-align: center;
    display: block;
    margin-bottom: -4%;
  }

  & button.ant-modal-close {
    background: black;
    color: white;
    border: 3px solid white;
    border-radius: 100%;
    right: -10px;
    top: -14px;
    width: 40px;
    height: 40px;
  }

  & .ant-modal-header {
    padding-top: 24px;
    border-bottom: none;
    cursor: var(--default-cursor);

    & .ant-modal-title {
      font-size: 22px;
    }
  }

  & .ant-modal-body {
    padding: 15px 24px;
    cursor: var(--default-cursor);
  }

  & span.ant-modal-close-x {
    line-height: initial;
    height: auto;
    width: auto;
    font-size: 17px;
  }

  & .color-label-container {
    display: flex;
    margin-top: 20px;
    column-gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 20px;

    & .color-label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & .color-size {
        height: 70px;
        width: 70px;
      }
    }
  }

  & .color-description {
    margin-top: 5px;
    font-size: 15px;
    font-weight: bold;
  }

  & .view-all-help-link {
    font-size: 20px;
    text-align: right;
    margin-top: 18px;
  }
}

@primary-color: #3c61b4;