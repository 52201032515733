.minus-margin-left-10 {
    margin-left: -10px;
}

.loader-hr {
    margin-top: 0px;
    margin-bottom: 0px;
    width: 350px;
}

.crop-type-loader {
    margin-top: -18px;
    margin-left: -12px;
}
@primary-color: #3c61b4;