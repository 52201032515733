.satellite-color-keys {
    height: 30px;
    width: 100%;
    font-size: 10px;
    min-width: 300px;
    padding: 0px 20px 0px 15px;
}

.satellite-color-keys-container {
    text-align: end;
    font-size: 13px;
    margin-right: 14px;
    font-family: sans-serif;
}

.satellite-color-image-bar {
    width: 90%;
    height: 16px;
    vertical-align: top;
    border: none;
    border-top: 1px solid #333;
    margin-left: 15px;
    margin-top: -3px;
}

.satellite-color-index-container {
    width: 100%;
    height: 10px;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    vertical-align: top;
    justify-content: space-between;
    margin-top: -5px;
}

.satellite-index-left {
    display: flex;
    justify-content: flex-start;
    margin-left: 16px;
}

.satellite-index-right {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
}

/* ----------- iPhone 5, 5S, 5C and 5SE -----------*/
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .mobile-render-discrete-colors {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .mobile-legend-container {
        width : calc(100vw - 23vw)
    }
}
@primary-color: #3c61b4;