
@padding: 0px 10px;

:root {
    --overall-padding: @padding;
}

.grid-display {
    display: grid;
}

.right-arrow-outline {
    margin-top: 23px; 
    margin-left: -9px;
    font-size: large;
}

.straight-arrow-line {
    border-top: 2px solid grey;
    width: inherit; 
    margin-top: 31px;
    margin-left: 10px;
}

.forecast-day-count {
    min-width: 50px;
    font-weight: bold;
    margin-left: 10px;
}

.forecast-container {
    display: flex;
    padding: 0px 20px;
}

.recommendation-state-text {
    white-space: nowrap;
}

/* ----------- iPhone 5, 5S, 5C and 5SE -----------*/
@media only screen and (min-device-width: 320px) and (max-device-width: 900px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {

    .forecast-container {
        display: flex;
        padding: 0px 0px;
    }

    .forecast-day-count, .straight-arrow-line {
        margin-left: 0px;
    }

    .forecast-day-count {
        min-width: 0px;
        font-weight: bold;
        margin-left: 10px;
        font-size: smaller;
    }

    .mobile-forecast-days {
        margin-left: -15px;
    }

    .right-arrow-outline {
       margin-top: 22px;
       font-size: medium;
    }

    .right-arrow-padding {
        padding: var(--overall-padding);
    }
  }

  @media only screen and (max-device-width: 350px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {  
    
    .align-item {
        align-items: center;
    }

    .right-arrow-padding {
        padding: var(--overall-padding);
    }
  }
@primary-color: #3c61b4;